import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CardtypeService {
  detectCardType(cardNumber: string): string {
    const visaPattern = /^4[0-9]{6,}$/;
    const mastercardPattern = /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;
    const mastercardPattern2 = /^2[2-7][0-9]{14}$/;
    const vervePattern = /^6(?:011|5[0-9]{2})[0-9]{3,}$/;
    const vervePattern2 = /^62[24568][0-9]{13}[0-9]*$/;
    const vervePattern3 =  /^6[45][0-9]{14}[0-9]*$/;

    if (visaPattern.test(cardNumber)) {
      return 'Visa';
    } else if (mastercardPattern.test(cardNumber) || mastercardPattern2.test(cardNumber)) {
      return 'MasterCard';
    } else if (vervePattern.test(cardNumber) || vervePattern2.test(cardNumber) || vervePattern3.test(cardNumber)) {
      return 'Verve';
    } else {
      return 'Unknown';
    }
  }
}
