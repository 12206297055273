import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalRef: NgbModalRef;
  value: any;

  constructor(private modalService: NgbModal, private http: HttpClient) { }

  setValue(newValue: any) {
    this.value = newValue;
  }

  getValue(): any {
    return this.value;
  }

  openModal(content: any, options?: any): void {
    this.modalRef = this.modalService.open(content, options);
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalService.dismissAll();
    }
  }

  unlockScreen(payload: any) {
    return this.http.post<any>(`${environment.baseUrl}/screen-unlock`, payload, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }});
  }

  getReferenceDetails(data: any) {
    return this.http.get(`${environment.baseUrlMerchant}/checkout/details/${data}`, { headers: {
      // Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }});
  }

  getPaymentAccountTransfer(merchant: any, payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/checkout/generate-payment-account`, payload, { headers: {
      merchantId: merchant
    }});
  }

  getPaymentAccountUssd(merchant: any, payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/checkout/generate-ussd-reference`, payload, { headers: {
      merchantId: merchant
    }});
  }

  getPaymentAccountCardMasterAndVerve(merchant: any, payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/checkout/card-payment`, payload, { headers: {
      merchantId: merchant
    }});
  }

  validateCardPaymentOtp(payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/checkout/card-payment/validate-otp`, payload, { headers: {
    }});
  }

  resendCardPaymentOtp(payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/checkout/card-payment/resend-otp`, payload, { headers: {
    }});
  }

}

