import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  getUserDetails() {
    return this.http.get(`${environment.baseUrlMerchant}/user-details`, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }});
  }

  uploadKycDocument(merchant: any, payload: any) {
    return this.http.post<any>(`${environment.baseUrlMerchant}/merchant/uploads`, payload, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      merchantId: merchant
    }});
  }

  getMerchantById(merchant: any) {
    return this.http.get(`${environment.baseUrlMerchant}/merchant/${merchant}`, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      merchantId: merchant
    }});
  }

  updateMerchant(id: any, payload: any) {
    return this.http.patch<any>(`${environment.baseUrlMerchant}/merchant/${id}`, payload, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      merchantId: id
    }});
  }

  getMerchantApplication(merchant) {
    return this.http.get(`${environment.baseUrl}/merchant/applications`, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      merchantId: merchant
    }});
  }

  getAllSummary(merchant: any) {
    return this.http.get(`${environment.baseUrlMerchant}/dashboard`, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      merchantId: merchant
    }});
  }

}
