<div class="vh-100" style="width: 100%; opacity: 0.1; background-image: url('assets/images/mine/whiteLogo.png'); background-repeat: repeat;">
    <div class="row">
        <div class="col-xl-4"></div>
        <div class="col-xl-4">
            <div class="card">
                <div class="card-body">
                    Checking it out oooodddd
                </div>
            </div>
        </div>
        <div class="col-xl-4"></div>
    </div>
</div>

<!-- <div>
    <iframe src="http://localhost:6710/account/visa-card-payment-confirm" width="100%" height="500"></iframe>
</div> -->