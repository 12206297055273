import { Component, OnInit, } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { VisaConfirmationComponent } from '../visa-confirmation/visa-confirmation.component';

@Component({
  selector: 'app-visa-page',
  templateUrl: './visa-page.component.html',
  styleUrls: ['./visa-page.component.scss']
})
export class VisaPageComponent implements OnInit {

  constructor(
    private modalService: ModalService,
  ) { }

  ngOnInit(): void {
    this.openModal();
  }

  openModal(): void {
    this.modalService.openModal(VisaConfirmationComponent, {centered: true, scrollable: true, size: 'md', backdrop: 'static'});
  }

}
