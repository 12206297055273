<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="big" color="#6F4183" type="ball-square-spin" [fullScreen]="true"></ngx-spinner>

<div class="modal-body" style="padding: 0px;">
    <div class="container-fluid p-0">
        <div class="mt-4 mb-3" *ngIf="otherDetails" style="padding: 0px 40px;">
            <img src="assets/images/mine/whiteLogo.png" height="50" />
        </div>
        <div class="" style="padding: 0px 40px;">
            <div class="row" *ngIf="showTopAll">
                <div class="col-xl-6">
                    <div class="row g-0" *ngIf="otherDetails">
                        <div class="col-12 col-xl-12">
                            <p class="mb-0 text-black fw-bold font-size-18">Pay with {{ sendType }}</p>
                        </div>
                        <!-- <div class="col-6 col-xl-6">
                    <p class=" mb-2 float-right text-black fw-bold font-size-18">NGN {{ referenceDetails.amount }}</p>
                </div> -->
                    </div>
                    <p *ngIf="otherDetails" class="mt-3 mb-2 text-black fw-bold font-size-15">Pay with:</p>
                    <div *ngIf="otherDetails" class="d-flex">
                        <div class="form-check mb-3" style="margin-right: 20px;">
                            <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" (change)="check('card')" [checked]="cardCheck">
                            <label class="form-check-label" for="formRadios1" style="margin-left: 0px;" [ngClass]="cardFirstView ? 'text-black' : 'text-light-grey'">
                      Card
                  </label>
                        </div>
                        <!-- <div class="form-check mb-3" style="margin-right: 20px;">
                            <input class="form-check-input" type="radio" name="formRadios" id="formRadios2" (change)="check('ussd')" [checked]="ussdCheck">
                            <label class="form-check-label" for="formRadios2" style="margin-left: 0px;" [ngClass]="ussdFirstView ? 'text-black' : 'text-light-grey'">
                      USSD
                  </label>
                        </div> -->
                        <div class="form-check mb-3">
                            <input class="form-check-input" type="radio" name="formRadios" id="formRadios3" (change)="check('transfer')" [checked]="transferCheck">
                            <label class="form-check-label" for="formRadios3" style="margin-left: 0px;" [ngClass]="transferFirstView ? 'text-black' : 'text-light-grey'">
                      Transfer
                  </label>
                        </div>
                    </div>


                    <form [formGroup]="cardForm">
                        <div class="row" *ngIf="cardFirstView">
                            <div class="col-xl-12">
                                <div class="mb-3 mt-3">
                                    <label class="ml-2 text-muted">Customer Name</label>
                                    <input [style.border-color]=" cardForm.get('customerName')?.errors && (cardForm.get('customerName')?.touched || cardForm.get('customerName')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="customerName" placeholder="Enter Customer Name">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="cardForm.get('customerName')?.errors && (cardForm.get('customerName')?.touched || cardForm.get('customerName')?.dirty)">
                  <span *ngIf="cardForm.get('customerName')?.errors?.['required']">Expired date is required</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12">
                                <div class="mb-3">
                                    <label class="ml-2 text-muted">Card Number</label>
                                    <input (blur)="getCardType()" [style.border-color]=" cardForm.get('cardNo')?.errors && (cardForm.get('cardNo')?.touched || cardForm.get('cardNo')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="cardNo" placeholder="xxxx xxxx xxxx xxxx">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="cardForm.get('cardNo')?.errors && (cardForm.get('cardNo')?.touched || cardForm.get('cardNo')?.dirty)">
                              <span *ngIf="cardForm.get('cardNo')?.errors?.['required']">Card No is required</span>
                                        <span *ngIf="cardForm.get('cardNo')?.errors?.['pattern']">Invalid input !!</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-6">
                                <div class="mb-2">
                                    <label class="ml-2 text-muted">Expire Date</label>
                                    <input maxlength="5" [style.border-color]=" cardForm.get('expireDate')?.errors && (cardForm.get('expireDate')?.touched || cardForm.get('expireDate')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="expireDate"
                                        placeholder="00/00">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="cardForm.get('expireDate')?.errors && (cardForm.get('expireDate')?.touched || cardForm.get('expireDate')?.dirty)">
                              <span *ngIf="cardForm.get('expireDate')?.errors?.['required']">Expired date is required</span>
                                        <span *ngIf="cardForm.get('expireDate')?.errors?.['pattern']">Invalid input !!</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-6">
                                <div class="mb-2">
                                    <label class="ml-2 text-muted">CVV</label>
                                    <input maxlength="3" [style.border-color]=" cardForm.get('cvv')?.errors && (cardForm.get('cvv')?.touched || cardForm.get('cvv')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="cvv" placeholder="000">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="cardForm.get('cvv')?.errors && (cardForm.get('cvv')?.touched || cardForm.get('cvv')?.dirty)">
                              <span *ngIf="cardForm.get('cvv')?.errors?.['required']">CVV is required</span>
                                        <span *ngIf="cardForm.get('cvv')?.errors?.['pattern']">Invalid input !!</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-4 col-xl-4">
                                <div class="mb-2">
                                    <label class="ml-2 text-muted">Pin</label>
                                    <div class="input-group auth-pass-inputgroup">
                                        <input maxlength="4" [style.border-color]=" cardForm.get('cardPin')?.errors && (cardForm.get('cardPin')?.touched || cardForm.get('cardPin')?.dirty) ? '#6F4183' : ''" [type]="ismyTextFieldType ? 'text' : 'password'" class="form-control formCheckout w-100"
                                            formControlName="cardPin" placeholder="Enter Pin">
                                        <span class="input-icon-checkout" (click)="togglePasswordFieldTyped()"><i [class]="ismyTextFieldType ? 'far fa-eye' : 'far fa-eye-slash'"></i></span>
                                        <div class="mt-1">
                                            <span class="text-danger" *ngIf="cardForm.get('cardPin')?.errors && (cardForm.get('cardPin')?.touched || cardForm.get('cardPin')?.dirty)">
                              <span *ngIf="cardForm.get('cardPin')?.errors?.['required']">Card Pin is required</span>
                                            <span *ngIf="cardForm.get('cardPin')?.errors?.['pattern']">Invalid input !!</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col xl-12 mt-4 pt-3">
                                <div class="d-grid mb-4">
                                    <button class="btn btn-primary btn-block" [disabled]="cardForm.value.cvv === ''" (click)="proceed1('card')">Pay {{ referenceDetails.currency }} {{ referenceDetails.amount }}</button>
                                </div>
                                <!-- <p class="mt-3 mb-5 font-size-10" style="color: #ACACAC;">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p> -->
                            </div>
                        </div>
                    </form>
                    <form [formGroup]="otpForm">
                        <div class="" *ngIf="cardSecondView">
                            <div class="row">
                                <div class="col-xl-12">
                                    <p class="text-black font-size-12">{{ otpPinMessage }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-1"></div>
                                <div class="col-xl-10">
                                    <div class="row g-2" style="padding: 0px 0px;">
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp1')?.errors && (otpForm.get('otp1')?.touched || otpForm.get('otp1')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input1 (keyup)="(input1.value.length == 1) ? input2.focus() : input1.focus()"
                                                    formControlName="otp1">
                                            </div>
                                        </div>
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp2')?.errors && (otpForm.get('otp2')?.touched || otpForm.get('otp2')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input2 (keyup)="(input2.value.length == 1) ? input3.focus() : input1.focus()"
                                                    formControlName="otp2">
                                            </div>
                                        </div>
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp3')?.errors && (otpForm.get('otp3')?.touched || otpForm.get('otp3')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input3 (keyup)="(input3.value.length == 1) ? input4.focus() : input2.focus()"
                                                    formControlName="otp3">
                                            </div>
                                        </div>
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp4')?.errors && (otpForm.get('otp4')?.touched || otpForm.get('otp4')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input4 (keyup)="(input4.value.length == 1) ? input5.focus() : input3.focus()"
                                                    formControlName="otp4">
                                            </div>
                                        </div>
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp5')?.errors && (otpForm.get('otp5')?.touched || otpForm.get('otp5')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input5 (keyup)="(input5.value.length == 1) ? input6.focus() : input4.focus()"
                                                    formControlName="otp5">
                                            </div>
                                        </div>
                                        <div class="col-2 col-xl-2">
                                            <div class="">
                                                <input [style.border-color]=" otpForm.get('otp6')?.errors && (otpForm.get('otp6')?.touched || otpForm.get('otp6')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100 text-center" maxlength="1" #input6 (keyup)="(input6.value.length == 1) ? input6.focus() : input5.focus()"
                                                    formControlName="otp6">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-1"></div>
                                <div class="col-xl-12 mt-2">
                                    <div class="text-center">
                                        <p>Click here to <span class="text-primary fw-bold cursor-pointer" (click)="resendCardOtp()">Resend Otp</span></p>
                                    </div>
                                </div>
                                <div class="col xl-12">
                                    <div class="d-grid mt-5 pt-5">
                                        <button class="btn btn-primary btn-block" [disabled]="!otpForm.value.otp1 || !otpForm.value.otp2 || !otpForm.value.otp3 || !otpForm.value.otp4" (click)="proceed2('card')">Confirm Payment</button>
                                    </div>
                                    <p class="mt-3 mb-5 font-size-10" style="color: #ACACAC;">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                                </div>
                            </div>
                        </div>
                    </form>

                    <form [formGroup]="ussdForm">
                        <div class="row" *ngIf="ussdFirstView">
                            <div class="col-xl-12">
                                <div class="mb-2 mt-4">
                                    <label class="ml-2 text-muted">Customer Name</label>
                                    <input [style.border-color]=" ussdForm.get('customerName')?.errors && (ussdForm.get('customerName')?.touched || ussdForm.get('customerName')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="customerName" placeholder="Enter Customer Name">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="ussdForm.get('customerName')?.errors && (ussdForm.get('customerName')?.touched || ussdForm.get('customerName')?.dirty)">
                                       <span *ngIf="ussdForm.get('customerName')?.errors?.['required']">Expired date is required</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 mt-2">
                                <p class="text-black font-size-12 mb-1">Choose your bank</p>
                            </div>
                            <div class="col-xl-12">
                                <div class="mb-3">
                                    <select [style.border-color]=" ussdForm.get('bank')?.errors && (ussdForm.get('bank')?.touched || ussdForm.get('bank')?.dirty) ? '#6F4183' : ''" class="form-select formCheckout w-100" formControlName="bank">
                                <option selected value="">Choose  Your Bank</option>
                                <option *ngFor="let list of bankList" value="{{ list.BankCode }}">{{ list.BankName }}</option>
                              </select>
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="ussdForm.get('bank')?.errors && (ussdForm.get('bank')?.touched || ussdForm.get('bank')?.dirty)">
                                    <span *ngIf="ussdForm.get('bank')?.errors?.['required']">Bank is required</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col xl-12">
                                <div class="mt-5 pt-5 pb-4 d-grid">
                                    <button class="btn btn-primary btn-block" [disabled]="!ussdForm.valid" (click)="proceed1('ussd')">Pay {{ referenceDetails.currency }} {{ referenceDetails.amount }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="ussdSecondView">
                        <div class="col-xl-12">
                            <p class="text-black font-size-12">Dial the code below to complete this transaction with {{ ussdBankInfo.BankName}}’s {{ ussdBankInfo.BankCode }}</p>
                            <p class="text-black text-center fw-bold mt-3" style="font-size: 18px;">{{ ussdAccountDetails.ussdString }} <span><i class="ri-file-copy-line ml-3" (click)="copyUssd(ussdAccountDetails.ussdString)"></i></span></p>
                            <p class="text-center mt-3 cursor-pointer" style="font-size: 11px; color: #ACACAC;">Expires in <span class="text-danger">{{ displayTimeNgn }}</span> seconds</p>
                        </div>
                        <div class="col xl-12">
                            <div class="mt-5 pt-5 d-grid">
                                <button class="btn btn-primary btn-block" (click)="proceed2('ussd')">Confirm Payment</button>
                            </div>
                        </div>
                    </div>

                    <form [formGroup]="transferForm">
                        <div class="row" *ngIf="transferFirstView">
                            <div class="col-xl-12">
                                <div class="mb-2 mt-4">
                                    <label class="ml-2 text-muted">Customer Name</label>
                                    <input [style.border-color]="cardForm.get('customerName')?.errors && (cardForm.get('customerName')?.touched || cardForm.get('customerName')?.dirty) ? '#6F4183' : ''" type="text" class="form-control formCheckout w-100" formControlName="customerName" placeholder="Enter Customer Name">
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="cardForm.get('customerName')?.errors && (cardForm.get('customerName')?.touched || cardForm.get('customerName')?.dirty)">
                          <span *ngIf="cardForm.get('customerName')?.errors?.['required']">Expired date is required</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-12 mt-2">
                                <p class="text-black font-size-12 mb-1">Choose your bank</p>
                            </div>
                            <div class="col-xl-12">
                                <div class="mb-3">
                                    <select [style.border-color]=" ussdForm.get('bank')?.errors && (ussdForm.get('bank')?.touched || ussdForm.get('bank')?.dirty) ? '#6F4183' : ''" class="form-select formCheckout w-100" formControlName="bank">
                              <option selected value="">Choose  Your Bank</option>
                              <option *ngFor="let list of bankList" value="{{ list.BankCode }}">{{ list.BankName }}</option>
                            </select>
                                    <div class="mt-1">
                                        <span class="text-danger" *ngIf="ussdForm.get('bank')?.errors && (ussdForm.get('bank')?.touched || ussdForm.get('bank')?.dirty)">
                                  <span *ngIf="ussdForm.get('bank')?.errors?.['required']">Bank is required</span>
                                        </span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col xl-12 mt-4 pt-5">
                                <div class="mt-5 pt-5 pb-4 d-grid">
                                    <button class="btn btn-primary btn-block" [disabled]="transferForm.value.customerName === ''" (click)="proceed1('transfer')">Pay {{ referenceDetails.currency }} {{ referenceDetails.amount }}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="transferSecondView">
                        <div class="col-xl-12 text-center">
                            <p class="font-size-16 mb-3 mt-4" style="color: #ACACAC;">Transfer NGN {{ referenceDetails.amount }} to:</p>
                            <h5 class="text-black dont-size-10 mb-0">{{ transferAccountDetails.bank }}</h5>
                            <p class="text-black fw-bold" style="font-size: 22px;">{{ transferAccountDetails.accountNumber }} <span class="cursor-pointer" style="color: #ACACAC;" (click)="copyAccNo(transferAccountDetails.accountNumber)"><i class="bx bx-copy"></i></span></p>
                            <p class="mb-3" style="color: #ACACAC;">Expires in <span class="text-primary">{{ displayTimeNgn }}</span> seconds</p>
                        </div>
                        <div class="col xl-12">
                            <div class="mt-5 pt-5 d-grid">
                                <button class="btn btn-primary btn-block" (click)="proceed2('transfer')">Confirm Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="" style="background: url(assets/images/mine/checkoutBackground.png); height: 500px; margin-top: -35px; background-size: contain; background-repeat: no-repeat;">
                        <div class="" style="padding: 0px 55px">
                            <p class="fs-14 fw-600 mb-2" style="color: #575757; padding-top: 40px;">You’re paying,</p>
                            <h2 class="fw-600" style="color: #000; font-size: 40px;">{{ referenceDetails.amount | currency : referenceDetails.currency : "symbol-narrow" }}</h2>
                            <h5 class="fw-600 mt-4 mb-4" style="color: #000; font-size: 20px;">{{ referenceDetails.narration }}</h5>
                            <hr>
                            <div class="row mt-4">
                                <div class="col-xl-6">
                                    <h5 class="fw-600 mb-4" style="color: #000; font-size: 17px;">Tax</h5>
                                </div>
                                <div class="col-xl-6">
                                    <h5 class="fw-500 mb-4 float-right" style="color: #000; font-size: 17px;">{{ 0 | currency : referenceDetails.currency : "symbol-narrow" }}</h5>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-xl-6">
                                    <h5 class="fw-600 mb-4" style="color: #000; font-size: 17px;">Total</h5>
                                </div>
                                <div class="col-xl-6">
                                    <h5 class="fw-500 mb-4 float-right" style="color: #000; font-size: 17px;">{{ referenceDetails.amount | currency : referenceDetails.currency : "symbol-narrow" }}</h5>
                                </div>
                            </div>
                            <p class="fs-8" style="color: #9563FF; padding-top: 75px;">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="confirmingTransactionView">
                <div class="col-xl-12">
                    <div class="text-center mt-5 pt-4" style="margin-bottom: 70px;">
                        <img src="assets/images/mine/checkoutLoading.png" height="60px" />
                        <h5 class="text-black" style="margin-top: 80px;">Confirming your transaction</h5>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="successView">
                <div class="col-xl-12">
                    <div class="text-center mt-4">
                        <img src="assets/images/mine/whiteLogo.png" height="50" />
                    </div>
                    <div class="text-center mt-5">
                        <img src="assets/images/mine/successCheck.png" height="120" />
                    </div>
                    <div class="text-center">
                        <h5 class="text-black mt-2 pt-1">Payment Successful</h5>
                    </div>
                </div>
                <div class="col xl-12 mb-5 pb-4">
                    <div class="mt-4 d-grid">
                        <button class="btn btn-primary btn-block" (click)="doneTransfer()">Close the browser tab</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>