import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Toaster } from 'ngx-toast-notifications';
import { OnboardingService } from 'src/app/account/auth/services/onboarding.service';
import { ModalService } from '../services/modal.service';
import { DashboardService } from 'src/app/pages/services/dashboard.service';

@Component({
  selector: 'app-kycmodal',
  templateUrl: './kycmodal.component.html',
  styleUrls: ['./kycmodal.component.scss']
})
export class KycmodalComponent implements OnInit {
  kycForm: FormGroup;

  showBusinessInfo = false;
  showDirectorInfo = true;
  showUploadInfo = false;
  showFinal = false;
  showCifUpload = true;
  showCifResult = false;
  cifUploadClass: boolean;
  showMemUpload = true;
  showMemResult = false;
  memUploadClass: boolean;
  showNinUpload = true;
  showNinResult = false;
  ninUploadClass: boolean;
  showNoBusiness = true;
  showTickBusiness = false;
  showNoDirector = false;
  showNoDirectorGreen = true;
  showNoDirectorRed = false;
  showTickDirector = false;
  showTextDirector = true;
  showNoVerify = true;
  showNoVerifyGreen = false;
  showNoVerifyRed = true;
  showTickVerify = false;
  showTextVerify = false;
  userDetails: any;
  businessNamePass: any;
  businessCategoryPass: any;
  businessTypePass: any;
  businessTypeOtherPass: any;
  uploadedCif = '';
  uploadedMem = '';
  uploadedNin = '';
  cifFileName: any;
  memFileName: any;
  ninFileName: any;
  docArray: any[] = [];
  merchantDetails: any;
  rcNumberToPass: any;
  socialLinkToPass: any;

  countryList = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Åland Islands', code: 'AX'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'American Samoa', code: 'AS'},
    {name: 'AndorrA', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Anguilla', code: 'AI'},
    {name: 'Antarctica', code: 'AQ'},
    {name: 'Antigua and Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Aruba', code: 'AW'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bermuda', code: 'BM'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia and Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Bouvet Island', code: 'BV'},
    {name: 'Brazil', code: 'BR'},
    {name: 'British Indian Ocean Territory', code: 'IO'},
    {name: 'Brunei Darussalam', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Cape Verde', code: 'CV'},
    {name: 'Cayman Islands', code: 'KY'},
    {name: 'Central African Republic', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Christmas Island', code: 'CX'},
    {name: 'Cocos (Keeling) Islands', code: 'CC'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Congo, The Democratic Republic of the', code: 'CD'},
    {name: 'Cook Islands', code: 'CK'},
    {name: 'Costa Rica', code: 'CR'},
    {name: 'Cote D\'Ivoire', code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Falkland Islands (Malvinas)', code: 'FK'},
    {name: 'Faroe Islands', code: 'FO'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'French Guiana', code: 'GF'},
    {name: 'French Polynesia', code: 'PF'},
    {name: 'French Southern Territories', code: 'TF'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Gibraltar', code: 'GI'},
    {name: 'Greece', code: 'GR'},
    {name: 'Greenland', code: 'GL'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guadeloupe', code: 'GP'},
    {name: 'Guam', code: 'GU'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guernsey', code: 'GG'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
    {name: 'Holy See (Vatican City State)', code: 'VA'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hong Kong', code: 'HK'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran, Islamic Republic Of', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Isle of Man', code: 'IM'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jersey', code: 'JE'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
    {name: 'Korea, Republic of', code: 'KR'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People\'S Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libyan Arab Jamahiriya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macao', code: 'MO'},
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Martinique', code: 'MQ'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mayotte', code: 'YT'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia, Federated States of', code: 'FM'},
    {name: 'Moldova, Republic of', code: 'MD'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montserrat', code: 'MS'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'Netherlands Antilles', code: 'AN'},
    {name: 'New Caledonia', code: 'NC'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'Niue', code: 'NU'},
    {name: 'Norfolk Island', code: 'NF'},
    {name: 'Northern Mariana Islands', code: 'MP'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Palestinian Territory, Occupied', code: 'PS'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Pitcairn', code: 'PN'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Puerto Rico', code: 'PR'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Reunion', code: 'RE'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'RWANDA', code: 'RW'},
    {name: 'Saint Helena', code: 'SH'},
    {name: 'Saint Kitts and Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Pierre and Miquelon', code: 'PM'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome and Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia and Montenegro', code: 'CS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Svalbard and Jan Mayen', code: 'SJ'},
    {name: 'Swaziland', code: 'SZ'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Taiwan, Province of China', code: 'TW'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Tanzania, United Republic of', code: 'TZ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tokelau', code: 'TK'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad and Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Turks and Caicos Islands', code: 'TC'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom', code: 'GB'},
    {name: 'United States', code: 'US'},
    {name: 'United States Minor Outlying Islands', code: 'UM'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Virgin Islands, British', code: 'VG'},
    {name: 'Virgin Islands, U.S.', code: 'VI'},
    {name: 'Wallis and Futuna', code: 'WF'},
    {name: 'Western Sahara', code: 'EH'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
  ];
  userRole: any[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private toaster: Toaster,
    private authService: OnboardingService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: ModalService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.kycForm = this.formBuilder.group({
      email: ['', [ Validators.required, Validators.pattern(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) ]],
      businessName: [ '', Validators.required],
      businessCategory: [ '', Validators.required],
      businessType: [ '', Validators.required],
      businessTypeOther: [ '', Validators.required],
      dateOfBirth: [ '', Validators.required],
      socialLink: [ '', Validators.required],
      nationality: [ '', Validators.required],
      residentialAddress: [ '', Validators.required],
      address: [ '', Validators.required],
      rcNumber: [ '', [ Validators.required, Validators.pattern('^[A-Za-z0-9]*$')]],
      firstName: [ '', [ Validators.required, Validators.pattern('^[A-Za-z]*$')]],
      lastName: [ '', [ Validators.required, Validators.pattern('^[A-Za-z]*$')]],
      phoneNumber: [ '', [ Validators.required, Validators.pattern('^[0-9]*$')]],
      phoneShort: [ '', Validators.required],
      bvn: [ '', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(11)]],
    });

    this.getUserDetails();

  }

  async getUserDetails(): Promise<void>{
    this.spinner.show();
    this.dashboardService.getUserDetails().toPromise().then(async (result: any) => {
      if (result.isSuccessful) {
        this.spinner.hide();
        this.userDetails = result.user;
        this.userRole = this.userDetails.roles;
        await this.getMerchantById();
      } else {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'User Details', type: 'danger' });
      }
    }, err => {
      this.spinner.hide();
      this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'User Details', type: 'danger' });
    });
  }

  async getMerchantById(): Promise<void>{
    this.spinner.show();
    this.dashboardService.getMerchantById(this.userDetails.merchantId).toPromise().then((result: any) => {
      if (result.isSuccessful) {
        this.spinner.hide();
        this.merchantDetails = result.merchant;
        this.businessNamePass = this.userDetails.merchantName;
        this.businessCategoryPass = this.merchantDetails.businessCategory;
        this.businessTypePass = this.merchantDetails.businessType;
        this.businessTypeOtherPass = this.merchantDetails.merchantName;
        this.rcNumberToPass = this.merchantDetails.rcNumber;
        this.socialLinkToPass = this.merchantDetails.socialMediaHandle;
      } else {
        this.spinner.hide();
        this.toaster.open({ text: result.responseMessage || result.message, caption: 'Merchant', type: 'danger' });
      }

      if (result.merchant.documents.length === 3) {
        this.showCifUpload = false;
        this.showCifResult = true;
        this.cifUploadClass = true;
        this.memUploadClass = true;
        this.showMemResult = true;
        this.showMemUpload = false;
        this.ninUploadClass = true;
        this.showNinResult = true;
        this.showNinUpload = false;
        this.uploadedCif = 'yes';
        this.uploadedMem = 'yes';
        this.uploadedNin = 'yes';
      } else {

      }

    }, err => {
      this.spinner.hide();
      this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Merchant', type: 'danger' });
    });
  }

  uploadCif(e: any): void{
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 3 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        this.toaster.open({ text: 'You cant upload file larger than 3mb !!', caption: 'Certificate of Incorporation', type: 'danger' });
      } else {
        this.cifFileName = file.name;
        const reader = new FileReader();
        reader.onload = async () => {
          const uploadDoc = reader.result;
          const fileImg: any = uploadDoc;
          const base64Cif = fileImg.split(',')[1];
          this.docArray.push({
            filename: this.cifFileName,
            base64String: base64Cif
          });
          this.spinner.show();
          const payload = {
            merchantId: this.userDetails.merchantId,
            merchantDocuments: this.docArray
          };
          await this.dashboardService.uploadKycDocument(this.userDetails.merchantId, payload).toPromise().then((result: any) => {
            if (result.isSuccessful) {
              this.spinner.hide();
              this.cifUploadClass = true;
              this.showCifResult = true;
              this.showCifUpload = false;
              this.uploadedCif = 'uploaded';
              this.docArray = [];
              // tslint:disable-next-line:max-line-length
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'Certificate of Incorporation', type: 'success' });
            } else {
              this.spinner.hide();
              this.cifUploadClass = false;
              this.showCifResult = true;
              this.showCifUpload = false;
              this.docArray = [];
              // tslint:disable-next-line:max-line-length
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'Certificate of Incorporation', type: 'danger' });
            }
          }, err => {
            this.spinner.hide();
            this.cifUploadClass = false;
            this.showCifResult = true;
            this.showCifUpload = false;
            this.docArray = [];
            // tslint:disable-next-line:max-line-length
            this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Certificate of Incorporation', type: 'danger' });
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }
  uploadMem(e: any): void{
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 3 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        this.toaster.open({ text: 'You cant upload file larger than 3mb !!', caption: 'Memorandum of Association', type: 'danger' });
      } else {
        this.memFileName = file.name;
        const reader = new FileReader();
        reader.onload = async () => {
          const uploadDoc = reader.result;
          const fileImg: any = uploadDoc;
          const base64Cif = fileImg.split(',')[1];
          this.docArray.push({
            filename: this.memFileName,
            base64String: base64Cif
          });
          this.spinner.show();
          const payload = {
            merchantId: this.userDetails.merchantId,
            merchantDocuments: this.docArray
          };
          await this.dashboardService.uploadKycDocument(this.userDetails.merchantId, payload).toPromise().then((result: any) => {
            if (result.isSuccessful) {
              this.spinner.hide();
              this.memUploadClass = true;
              this.showMemResult = true;
              this.showMemUpload = false;
              this.uploadedMem = 'uploaded';
              this.docArray = [];
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'Memorandum of Association', type: 'success' });
            } else {
              this.spinner.hide();
              this.memUploadClass = false;
              this.showMemResult = true;
              this.showMemUpload = false;
              this.docArray = [];
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'Memorandum of Association', type: 'danger' });
            }
          }, err => {
            this.spinner.hide();
            this.memUploadClass = false;
            this.showMemResult = true;
            this.showMemUpload = false;
            this.docArray = [];
            // tslint:disable-next-line:max-line-length
            this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'Memorandum of Association', type: 'danger' });
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }
  uploadNin(e: any): void{
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 3 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        this.toaster.open({ text: 'You cant upload file larger than 3mb !!', caption: 'National Identification Number', type: 'danger' });
      } else {
        this.ninFileName = file.name;
        const reader = new FileReader();
        reader.onload = async () => {
          const uploadDoc = reader.result;
          const fileImg: any = uploadDoc;
          const base64Cif = fileImg.split(',')[1];
          this.docArray.push({
            filename: this.ninFileName,
            base64String: base64Cif
          });
          this.spinner.show();
          const payload = {
            merchantId: this.userDetails.merchantId,
            merchantDocuments: this.docArray
          };
          await this.dashboardService.uploadKycDocument(this.userDetails.merchantId, payload).toPromise().then((result: any) => {
            if (result.isSuccessful) {
              this.spinner.hide();
              this.ninUploadClass = true;
              this.showNinResult = true;
              this.showNinUpload = false;
              this.uploadedNin = 'uploaded';
              this.docArray = [];
              // tslint:disable-next-line:max-line-length
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'National Identification Number', type: 'success' });
            } else {
              this.spinner.hide();
              this.ninUploadClass = false;
              this.showNinResult = true;
              this.showNinUpload = false;
              this.docArray = [];
              // tslint:disable-next-line:max-line-length
              this.toaster.open({ text: result.responseMessage || result.message, caption: 'National Identification Number', type: 'danger' });
            }
          }, err => {
            this.spinner.hide();
            this.ninUploadClass = false;
            this.showNinResult = true;
            this.showNinUpload = false;
            this.docArray = [];
            // tslint:disable-next-line:max-line-length
            this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'National Identification Number', type: 'danger' });
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }

  deleteUploadedFile(val: any): void{
    if (val === 'cif') {
      this.showCifResult = false;
      this.showCifUpload = true;
      this.uploadedCif = '';
      const indexToDelete = this.docArray.findIndex(item => item.filename === this.cifFileName);
      if (indexToDelete !== -1) {
        this.docArray.splice(indexToDelete, 1);
      }
    } else if (val === 'mem') {
      this.showMemResult = false;
      this.showMemUpload = true;
      this.uploadedMem = '';
      const indexToDelete = this.docArray.findIndex(item => item.filename === this.memFileName);
      if (indexToDelete !== -1) {
        this.docArray.splice(indexToDelete, 1);
      }
    } else {
      this.showNinResult = false;
      this.showNinUpload = true;
      this.uploadedNin = '';
      const indexToDelete = this.docArray.findIndex(item => item.filename === this.ninFileName);
      if (indexToDelete !== -1) {
        this.docArray.splice(indexToDelete, 1);
      }
    }
  }

  async proceed(val: any): Promise<void>{
    if (val === 'first') {
      // tslint:disable-next-line:max-line-length
      if (!this.kycForm.get('businessName').valid || !this.kycForm.get('businessCategory').valid || !this.kycForm.get('businessType').valid
       || !this.kycForm.get('rcNumber').valid) {
        this.toaster.open({ text: 'Invalid Informations', caption: 'KYC Details', type: 'danger' });
      } else {
        this.showBusinessInfo = false;
        this.showDirectorInfo = true;
        this.showUploadInfo = false;
        this.showFinal = false;
        this.showNoBusiness = false;
        this.showTickBusiness = true;
        this.showNoDirector = false;
        this.showNoDirectorGreen = true;
        this.showNoDirectorRed = false;
        this.showTextDirector = true;
      }
    } else if (val === 'second') {
      if (!this.kycForm.get('firstName').valid || !this.kycForm.get('lastName').valid || !this.kycForm.get('phoneNumber').valid
       || !this.kycForm.get('bvn').valid || !this.kycForm.get('dateOfBirth').valid || !this.kycForm.get('email').valid || !this.kycForm.get('residentialAddress').valid) {
        this.toaster.open({ text: 'Invalid Informations', caption: 'KYC Details', type: 'danger' });
      } else {
        this.showBusinessInfo = false;
        this.showDirectorInfo = false;
        this.showUploadInfo = true;
        this.showFinal = false;
        this.showTickDirector = true;
        this.showNoDirectorGreen = false;
        this.showNoVerify = false;
        this.showNoVerifyGreen = true;
        this.showNoVerifyRed = false;
        this.showTextVerify = true;
      }
    } else {
      if ( this.uploadedCif === '' || this.uploadedMem === '' || this.uploadedNin === '') {
        this.toaster.open({ text: 'Upload Required Documents', caption: 'KYC Details', type: 'danger' });
      } else {
        this.spinner.show();
        const payload = {
          merchantId: this.userDetails.merchantId,
          phoneNumber: this.merchantDetails.phoneNumber,
          merchantName: `${this.userDetails.firstName} ${this.userDetails.lastName}`,
          email: this.userDetails.email,
          state: this.userDetails.state,
          address: this.kycForm.value.residentialAddress,
          businessCategory: this.merchantDetails.businessCategory,
          businessType: this.merchantDetails.businessType,
          city: this.merchantDetails.city,
          rcNumber: this.merchantDetails.rcNumber,
          companyName: this.userDetails.merchantName,
          country: this.userDetails.country,
          socialMediaHandle: this.kycForm.value.socialLink,
          directors: [
            {
              directorSalutation: '',
              directorSex: '',
              directorDateOfBirth: this.kycForm.value.dateOfBirth,
              directorIdentificationType: '',
              directorIdentificationNumber: '',
              directorFirstName: this.kycForm.value.firstName,
              directorLastName: this.kycForm.value.lastName,
              directorEmail: this.kycForm.value.email,
              directorBvn: this.kycForm.value.bvn,
              directorPhoneNumber: this.kycForm.value.phoneNumber,
              isVerified: true,
              isPrimary: true,
              base64String: '',
              uploadUrl: '',
              publicUrl: '',
              filename: ''
            }
          ]
        };
        await this.dashboardService.updateMerchant(this.userDetails.merchantId, payload).toPromise().then((result: any) => {
          if (result.isSuccessful) {
            this.spinner.hide();
            this.showBusinessInfo = false;
            this.showDirectorInfo = false;
            this.showUploadInfo = false;
            this.showFinal = true;
            this.showNoVerifyGreen = false;
            this.showTickVerify = true;
          } else {
            this.spinner.hide();
            this.toaster.open({ text: result.responseMessage || result.message, caption: 'KYC Verification', type: 'danger' });
          }
        }, err => {
          this.spinner.hide();
          this.toaster.open({ text: err.error.responseMessage || err.error.message, caption: 'KYC Verification', type: 'danger' });
        });
      }
    }
  }

  goBack(val: any): void{
    if (val === 'first') {
      this.showBusinessInfo = true;
      this.showDirectorInfo = false;
      this.showUploadInfo = false;
      this.showFinal = false;
      this.showNoBusiness = true;
      this.showTickBusiness = false;
      this.showNoDirector = true;
      this.showNoDirectorGreen = false;
      this.showNoDirectorRed = true;
      this.showTextDirector = false;
      this.showTickDirector = false;
    } else {
      this.showBusinessInfo = false;
      this.showDirectorInfo = true;
      this.showUploadInfo = false;
      this.showFinal = false;
      this.showNoDirectorGreen = true;
      this.showTickDirector = false;
      this.showNoVerify = true;
      this.showTextVerify = false;
      this.showNoVerifyGreen = false;
      this.showNoVerifyRed = true;
      this.showTickVerify = false;
    }
  }

  toDashboard(): void{
    this.router.navigateByUrl('dashboard');
    this.modalService.closeModal();
    window.location.reload();
  }

  doItLater(): void{
    this.modalService.closeModal();
    sessionStorage.setItem('kyclater', 'yes');
  }

}
