import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  sharedData: any;

  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  setData(data: any) {
    this.sharedData = data;
  }

  getData() {
    return this.sharedData;
  }

  login(data: any) {
    return this.http.post(`${environment.baseUrl}/user-login`, data);
  }

  register(data: any) {
    return this.http.post(`${environment.baseUrl}/onboarding/registration`, data);
  }

  logout() {
    this.router.navigateByUrl('account/login');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.clear();
  }

  loggedInUser() {
    return sessionStorage.getItem('token');
  }

  resetPassword(data: any) {
    return this.http.get(`${environment.baseUrl}/password-reset/${data}`);
  }

  resetPasswordUpdate(data: any) {
    return this.http.post(`${environment.baseUrl}/password-reset/update-password`, data);
  }

  verifyOtp(data: any) {
    return this.http.post(`${environment.baseUrl}/onboarding/verify-otp`, data);
  }

  verifyToken(data: any) {
    return this.http.post(`${environment.baseUrl}/onboarding/verify-token`, data);
  }

  resendOtp(data: any) {
    return this.http.get(`${environment.baseUrl}/onboarding/verify-token/resend/${data}`);
  }

  validateToken(data: any) {
    return this.http.post(`${environment.baseUrl}/validate-2factor-token`, data);
  }

  changePassword(data: any) {
    return this.http.post(`${environment.baseUrl}/change-password`, data, { headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }});
  }

}
