import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        isLayout: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bx-bar-chart-square',
        link: '/dashboard',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    {
      id: 3,
      label: 'MENUITEMS.TEAMS.TEXT',
      icon: 'bxs-group',
      link: '/teams',
      role: ['merchant-admin'],
    },
    {
        id: 4,
        label: 'MENUITEMS.WALLET.TEXT',
        icon: 'bx-wallet',
        link: '/wallet',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    {
        id: 5,
        label: 'MENUITEMS.COLLECTION.TEXT',
        icon: 'bx-data',
        link: '/collection',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    {
        id: 6,
        label: 'MENUITEMS.SETTLEMENTS.TEXT',
        icon: 'bx-receipt',
        // icon: icon: '../../../assets/images/mine/mainLogo.png',
        link: '/settlements',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    // {
    //   id: 7,
    //   label: 'MENUITEMS.BILLS.TEXT',
    //   icon: 'bx-file',
    //   link: '/bills',
    //   role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    // },
    {
      id: 7,
      label: 'MENUITEMS.CARDS.TEXT',
      icon: 'bx-credit-card-alt',
      link: '/cards',
      role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    {
        id: 8,
        label: 'MENUITEMS.PAYMENT.TEXT',
        icon: 'bx-check-square',
        link: '/payment',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
    {
        id: 9,
        label: 'MENUITEMS.PAYOUT.TEXT',
        icon: 'bx-area',
        link: '/payout',
        role: ['merchant-admin', 'merchant-support', 'merchant-treasury', 'merchant-developer'],
    },
];

